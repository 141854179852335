// Forgot page is a temporary solution!
// Modal forgot-password is primary use-case!
.Forgot {
    display: flex;
    align-items: center;
    justify-content: center;
    // animation: zoomIn 0.2s ease-in;

    .forgot-modal {
        max-width: 740px;
        box-shadow: none;
        background: transparent;
        border: none;
        padding: 0;
        overflow: visible;

        &__footer {
            @include media-breakpoint-down(md) {
                flex-flow: column;
            }
        }

        &__submit {
            padding: 5px 15px;
            &.magic-link {
                margin-bottom: 0;
                height: unset;
                transform: translateY(0);
            }
        }

        &__exit {
            display: none; // hide button on page
        }

        &__link {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            color: black;

            @include media-breakpoint-down(md) {
                margin-top: 1rem;
                display: block;
            }
        }
    }
}
