.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 600px;

    @include media-breakpoint-down(xs) {
        min-height: 400px;
    }

    &__title {
        font-size: 100px;

        @include media-breakpoint-down(xs) {
            font-size: 80px;
        }
    }

    &__subtitle {
        font-size: 28px;
        font-weight: 600;
    }

    &__text {
        font-size: 18px;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    &__btn {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        padding: 5px 25px;
        background-color: var(--main-color, #7f92f3);
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        transition: 0.25s;

        &:hover {
            box-shadow: 0 3px 8px 0 var(--main-color, #7f92f3);
            color: #fff;
        }
    }
}
