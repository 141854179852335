&-view {
    margin-top: 15px;

    &__header {
        display: flex;
        align-items: center;
        color: #9bacb6;
        font-size: 15px;
        border-bottom: 2px solid #eaf0f4;
        padding-bottom: 5px;
        font-weight: 500;
    }

    &__time {
        width: 270px;
        margin-left: 15px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__date {
        color: #3b454b;
        font-weight: bold;

        @include media-breakpoint-down(md) {
            margin-right: 0.7rem;
        }
    }

    &__file-name {
        width: 40%;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__list {
        max-height: 600px;
        overflow: auto;

        // @include customize-scrollbars(8px, $main-color, #fbfbfb, hidden);
        @include media-breakpoint-down(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 5px 5px 5px 15px;

        &:not(:last-child) {
            border-bottom: 2px solid #eaf0f4;
        }

        &:hover {
            .files-view {
                &__button {
                    &-delete {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
            margin: 0.7rem 0;
            width: 297px;
            padding: 0 0 10px;
            box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
            border-radius: 8px;

            &:not(:last-child) {
                border: none;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        width: 270px;

        @include media-breakpoint-down(md) {
            width: 100%;
            align-items: center;
            background: #eaf0f4;
            border-radius: 8px 8px 0 0;
            padding: 5px 10px;
            color: #fff;
            font-size: 15px;

            &.customer {
                background-color: var(--main-color, #7f92f3);
            }

            &.default {
                background-color: var(--main-color, #7ac8ed);
            }

            &.draft {
                background-color: #77cd61;
            }

            &.revision {
                background-color: var(--main-color, #7f92f3);
            }

            &.plagiarism {
                background-color: #77cd61;
            }
        }
    }

    &__status {
        .file-icon {
            display: flex;
            align-items: center;

            svg {
                height: 22px;
                margin-right: 0.5rem;
            }

            // color: #9bacb6;
            font-size: 15px;
            font-weight: bold;

            &.customer {
                color: var(--main-color, #7f92f3);
            }

            &.default {
                color: var(--main-color, #7ac8ed);
            }

            &.draft {
                color: #77cd61;
            }

            &.revision {
                color: var(--main-color, #7f92f3);
            }

            &.plagiarism {
                color: #77cd61;
            }

            @include media-breakpoint-down(md) {
                color: #3b454b !important;
                font-size: 14px;
                font-weight: normal;

                svg {
                    display: none;
                }
            }
        }
    }

    &__name {
        flex: 1 1 auto;
        color: #3b454b;
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(md) {
            max-width: 240px;
            margin-top: 1rem;
        }
    }

    &__buttons {
        display: flex;

        @include media-breakpoint-down(md) {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    &__button {
        &-delete {
            margin-right: 25px;
            background: none;
            border: none;
            font-size: 14px;
            color: #d76c6d;
            font-weight: 500;
            opacity: 0;
            transform: translateX(150px);
            transition: 0.25s;
        }

        &-download {
            padding: 10px;
            border: none;
            background-color: var(--main-color, #7f92f3);
            border-radius: 23px;
            width: 255px;
            color: #ffffff;
            font-size: 18px;
            font-weight: 500;
            z-index: 1;
            text-align: center;
            cursor: pointer;

            &:hover {
                color: #fff;
            }

            @include media-breakpoint-down(md) {
                width: 200px;
            }
        }
    }

    &__empty {
        text-align: center;
        padding: 15px 0;
        font-weight: bold;
    }
}
