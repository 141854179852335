.tablinks {
    &__list {
        display: flex;
        margin-bottom: 16px;
        border-bottom: 2px solid #eaf0f4;
        width: 100%;
        padding: 0 10px;

        @media screen and (max-width: 350px) {
            padding: 0;
        }

        li {
            @include media-breakpoint-down(md) {
                flex-grow: 1;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        color: #3b454b;
        font-weight: 700;
        padding: 3px 10px;
        border: 2px solid transparent;
        border-radius: 5px 5px 0 0;
        min-height: 34px;
        height: calc(100% + 2px);
        transition: $transition;

        @include media-breakpoint-down(md) {
            padding: 3px 8px;
            justify-content: center;
        }

        @include media-breakpoint-down(xs) {
            padding: 3px 5px;
        }

        &.active {
            border-color: #eaf0f4;
            border-bottom-color: #fff;

            svg {
                fill: $main-color;
            }

            .tablinks__label {
                display: inline;
            }

            .tablinks__counter {
                margin-right: 0;
            }
        }

        &:hover {
            color: $main-color;

            svg {
                fill: $main-color;
            }
        }

        .tablinks__counter {
            display: inline-block;
            margin-top: -3px;
            margin-left: 5px;
            color: #9bacb6;
            font-weight: 600;
            font-size: 12px;

            @include media-breakpoint-down(md) {
                font-size: 13px;
                margin-top: -5px;
            }

            @include media-breakpoint-down(sm) {
                margin-top: -13px;
                margin-left: 2px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 11px;
            }

            .counter-value {
                color: $main-color;
            }
        }

        svg {
            width: 16px;
            fill: #9bacb6;
            transition: $transition;

            @include media-breakpoint-down(sm) {
                width: 18px;
            }

            @media screen and (max-width: 350px) {
                width: 16px;
            }

            &.new-order {
                fill: #5ed257;
            }
        }
    }

    &__label {
        margin-left: 8px;
        font-size: 16px;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
            font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}
