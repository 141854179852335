.discount {
    &__title {
        color: #ee5ca1;
        font-weight: 700;
        font-size: 24px;

        @include media-breakpoint-down(xs) {
            font-size: 20px;
        }

        span:first-child {
            color: #3b454b;
        }

        .label {
            text-transform: uppercase;
        }

        span:last-child {
            @include media-breakpoint-down(xs) {
                white-space: nowrap;
            }
        }
    }

    &__level-wrapper {
        margin-bottom: 20px;
    }

    &__bar-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        width: 510px;
        border-radius: 9px;
        background-color: #eaf0f4;
        text-align: center;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 18px;

        @include media-breakpoint-down(xs) {
            font-size: 14px;
            height: 15px;
            width: 290px;
        }
    }

    &__bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 9px;
        background-color: #78cd62;
        overflow: hidden;
    }

    &__points {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 510px;
        color: #fff;
        height: 100%;
        font-size: 14px;

        @include media-breakpoint-down(xs) {
            width: 290px;
        }
    }

    &__goal {
        display: inline-block;
        width: 510px;
        text-align: right;
        color: #9bacb6;
        font-size: 15px;

        @include media-breakpoint-down(xs) {
            font-size: 13px;
            width: 290px;
        }
    }
}
