@mixin customize-scrollbars(
    $size: 6px,
    $thumb: $main-color,
    $background: #fbfbfb,
    $visibility: hidden,
    $border-radius: 10px
) {
    // Customize scrollbars
    /* Let's get this party started */
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
        box-shadow: inset 1px 1px 1px rgba(black, 0.07);
        border-radius: $border-radius;
        background: $background;
        visibility: $visibility;
    }

    &:hover::-webkit-scrollbar-track {
        visibility: visible;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: $border-radius;
        background: $thumb;
        visibility: $visibility;
    }

    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
    }
}
