.discount {
    &__heading {
        font-size: 16px;
        &,
        a {
            color: #3b454b;
        }
        a {
            border-bottom: 1px dashed rgba(#3b454b, 0.2);
        }
    }
    &__description {
        margin-bottom: 1rem;
        color: #3b454b;
        font-size: 1rem;
    }

    &__code-wrapper {
        display: flex;
        background-color: #eaf0f4;
        padding: 12px 20px 12px 23px;
        margin-bottom: 70px;
        border-radius: 8px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 10px;
        }
    }

    &__code-left {
        width: 40px;
        height: 40px;
        margin-right: 15px;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }
    &__code-right {
        flex: 1;
    }

    &__code-title {
        color: #3b454b;
        font-weight: 700;
        font-size: 18px;

        @include media-breakpoint-down(xs) {
            font-size: 17px;
            line-height: 20px;
        }
    }

    &__create {
        border: none !important;
        border-radius: 20px;
        font-weight: 600;
        padding: 0.2rem 0.8rem;
        margin: 0 0.5rem;
        color: #fff;
        transition: $transition;
        background-color: #59d053;
        box-shadow: 0 2px 4px rgba(#59d053, 0.4);

        &[disabled] {
            pointer-events: none;
            opacity: 0.5 !important;
        }

        &:hover {
            color: #fff;
            background-color: #59d053;
            box-shadow: 0 5px 10px 0 rgba(#59d053, 0.4);
        }
    }

    &__generate-box {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(xs) {
            justify-content: center;
            margin-bottom: 0;
        }
    }

    &__link {
        color: $main-color;
        font-weight: 600;
        transition: $transition;
    }
}

.code-box {
    &__regenerate {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: transparent;
        border: none;
        box-shadow: none;
        offset: none;
    }

    &__regenerate,
    &__copy,
    &__order {
        min-width: 35px;
        white-space: nowrap;
        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }
    }

    &__regenerate {
        width: 32px;
        height: 36px;

        svg {
            width: 16px;
            height: 16px;
            fill: #9bacb6;
        }
    }

    &__code {
        font-size: 18px;
        color: #2d4eed;
        margin: 0 7px 0 7px;
        font-family: monospace;
        font-weight: bold;
        border: 2px dashed rgba(#2d4eed, 0.2);
        padding: 0 8px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }
    }

    &__copy {
        margin-right: 15px;
        box-shadow: 0 2px 4px rgba(#2d4eed, 0.4);

        &:hover {
            box-shadow: 0 5px 10px rgba(#2d4eed, 0.4);
        }
    }

    &__divider {
        width: 2px;
        height: 35px;
        background-color: rgba(#c6d5df, 0.3);
        margin-right: 15px;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &__order {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background-color: #59d053;
        border-radius: 20px;
        height: 35px;
        padding: 0.3rem 0.8rem;
        color: #fff;
        transition: $transition;
        box-shadow: 0 2px 4px rgba(#59d053, 0.4);

        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }

        &:hover {
            color: #fff;
            box-shadow: 0 5px 10px 0 rgba(#59d053, 0.4);
        }

        svg {
            width: 20px;
            height: 20px;
            fill: #fff;
            margin-right: 10px;
        }
    }
}
