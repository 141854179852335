%slide-button-initial {
    font-size: 1.25rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #d2d9f4;
    box-sizing: border-box;
    transition: all 0.2s ease;
    z-index: 1;
    background: white;
}
%slide-button-active {
    box-shadow: 0 4px 15px rgba(var(--main-color-rgb), 0.4);
    border-color: var(--main-color);
    background: var(--main-color);
    color: white;
    transform: scale(1.2);
}

.WelcomeReferral {
    &__section {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        gap: 60px;
        color: #001434;
        min-height: 30vh;

        @include media-breakpoint-down(sm) {
            flex-flow: column;
        }
    }

    &__content {
        max-width: 50%;
        @include media-breakpoint-down(sm) {
            max-width: unset;
        }
        h1 {
            font-size: 64px;
            font-weight: 600;
            color: #001434;

            @include media-breakpoint-down(lg) {
                font-size: 42px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 24px;
                margin-top: -1rem;
            }
        }

        p {
            font-size: 22px;
            color: #001434;
            opacity: 0.75;
            @include media-breakpoint-down(lg) {
                font-size: 1.1rem;
            }
        }

        a {
            display: inline-flex;
            background: var(--main-color);
            color: #fff;
            padding: 0.8rem 5rem;
            border-radius: 35px;
            font-size: 18px;
            text-transform: uppercase;
            box-shadow: 0 4px 12px rgba(var(--main-color-rgb), 0.4);
            transition: all 0.2s ease;
            font-weight: bold;

            &:hover {
                box-shadow: 0 4px 18px rgba(var(--main-color-rgb), 0.6);
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                padding: 0.6rem 3rem;
            }
        }
    }

    &__thumbnail {
        height: unset;
        width: 50%;

        svg {
            max-width: 100%;
            width: 100%;
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(sm) {
                margin: -1rem auto;
            }
        }

        @include media-breakpoint-down(sm) {
            order: -1;
        }
    }
}

.ClickableSlider {
    display: flex;

    @include media-breakpoint-down(md) {
        flex-flow: column;
    }

    &__slides {
        max-width: 100%;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        counter-reset: slider;
        margin-top: 2rem;

        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    &__slide {
        cursor: pointer;
        counter-increment: slider;

        &:last-child {
            .ClickableSlider__heading::after {
                display: none;
            }
        }

        // Active
        &.active .ClickableSlider {
            &__heading::before {
                @extend %slide-button-active;
            }
        }
    }

    &__heading {
        font-size: 28px;
        font-weight: 500;
        color: #001434;

        &::before {
            content: "0" counter(slider);
            @extend %slide-button-initial;
            position: absolute;
            left: 0;
            top: 0;
        }
        &::after {
            content: "";
            position: absolute;
            top: 46px;
            left: 23px;
            bottom: 0;
            width: 1px;
            background: #d2d9f4;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }

    &__description {
        font-size: 1.2rem;
        margin: 0;
        color: #2f466b;
        opacity: 0.75;

        @include media-breakpoint-down(lg) {
            font-size: 1.1rem;
        }
    }

    &__buttons {
        display: none;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 1rem;
        order: 1;

        @include media-breakpoint-down(sm) {
            display: flex;
            margin-top: -3rem;
        }
    }
    &__button {
        @extend %slide-button-initial;

        &.active {
            @extend %slide-button-active;
        }
    }

    &__content {
        position: relative;
        padding: 0 60px 2rem 90px;

        @include media-breakpoint-down(md) {
            h4,
            p {
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
            padding-left: 80px;
        }
    }

    &__skeleton {
        position: relative;
    }

    &__active {
        display: flex;
        flex-flow: column;
        width: 75%;
        animation: popUp 0.3s ease;

        @include media-breakpoint-down(sm) {
            width: 100%;
            animation: fadeIn 0.2s ease-in;
        }

        h4 {
            font-size: 18px;
            font-weight: bold;
            color: #001434;
        }
        p {
            font-size: 1.1rem;
        }
        h4,
        p {
            color: #2f466b;
            display: none;
        }

        svg {
            max-width: 100%;
            margin-top: -1rem;
            height: unset;

            @include media-breakpoint-down(lg) {
            }

            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
        }

        @include media-breakpoint-down(sm) {
            margin-top: 3rem;
            h4,
            p {
                display: block;
            }
        }
    }

    &__logo-wrapper {
        position: absolute;
        left: 10%;
        top: 7%;
        width: 20%;
        display: flex;
    }
    &__logo {
        max-height: 55px;
        width: 100%;
    }
}

// SVG
.svg {
    &--fill-first {
        fill: var(--main-color);
    }
    &--fill-first-a {
        fill: white;
        opacity: 0.2;
    }

    &--fill-second {
        fill: #e5e9f3;
    }
    &--stroke-second {
        stroke: #e5e9f3;
    }

    &--fill-third {
        fill: #d5dae9;
    }
    &--fill-fourth {
        fill: #d7dbe9;
    }
    &--stroke-first {
        stroke: var(--main-color);
    }

    &--stroke-third {
        stroke: black;
    }
}
