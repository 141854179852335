$badge-dot-size: 12px;

.BadgeCounter {
    &__wrapper {
        color: #9bacb6;
        font-size: 0.95rem;
        padding: 0 6px;
    }
    &__value {
        color: $main-color;
        font-weight: 500;
    }
}

.BadgeDot {
    &__wrapper {
        position: relative;
    }
    &__dot {
        animation: badgePop 0.4s ease-in;
        position: absolute;
        top: -14px;
        right: -15px;
        // transform: translate(100%, -50%);
        display: flex;
        background: red;
        color: white;
        border-radius: 35px;
        width: $badge-dot-size;
        height: $badge-dot-size;
        align-items: center;
        justify-content: center;
        border: 3px solid white;
        box-sizing: border-box;
        will-change: transform;
    }
}
