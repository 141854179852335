@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate(50%, 30px);
    }

    to {
        opacity: 1;
        transform: translate(50%, -50%);
    }
}
.TransferBanner {
    position: fixed;
    top: 50%;
    right: 50%;
    background: white;
    border-radius: 12px;
    padding: 50px 60px;
    transform: translate(50%, -50%);
    min-width: 300px;
    border: 1px solid var(--main-color);
    box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15), 0 0 50px 10px #1558a809;

    @media (max-width: 660px) {
        padding: 30px 40px;
    }

    &__heading {
        display: block;
        font-size: 38px;
        font-weight: bold;
        overflow-wrap: break-word;
        margin: 1rem 0 1.5rem;
        line-height: 1.2;

        @media (max-width: 660px) {
            font-size: 24px;
        }

        :global {
            em {
                color: var(--main-color);
                font-style: normal;
                display: block;

                @media (max-width: 660px) {
                    display: inline;
                }
            }
        }
    }

    &__description {
        display: block;
        font-size: 19px;
        margin-top: 1.4rem;
    }
}
