.referral-tab {
}

@mixin buttonVariant($bgColor: #252525, $textColor: white) {
    background: $bgColor;
    box-shadow: 0 4px 15px rgba($bgColor, 0.4);
    color: white;

    &:hover {
        background: $bgColor;
        box-shadow: 0 6px 20px rgba($bgColor, 0.5);
    }
}
@mixin labelVariant($mainColor, $secondaryColor: white) {
    border: 1px solid $mainColor;
    color: $mainColor;
}
@mixin flexCenter {
    display: flex;
    align-items: center;
}
%icon {
    span[role="img"] {
        color: #9bacb6;
    }
}

.referrals-list {
    &__wrapper {
        gap: 30px;
        margin-bottom: 30px;
    }

    &__heading {
        @include flexCenter;
        @extend %icon;
        gap: 5px;
    }

    &__item {
        border: 2px solid #eaf0f4;
        background: rgba(#eaf0f4, 0.5);
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 15px;
        color: #9bacb6;

        .label,
        .button {
            display: inline-flex;
            align-items: center;
            gap: 5px;
        }

        .label {
            border-radius: 4px;
            padding: 0.1rem;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
            min-width: 90px;
            background: white;
            border: 1px solid #eaf0f4;
            color: #6e8b9f;

            &.success {
                @include labelVariant(#59d053);
            }
            &.primary {
                @include labelVariant($primary);
            }
            &.muted {
                @include labelVariant(#c9d2db, #252525);
            }

            @include media-breakpoint-down(xs) {
                font-size: 12px;
                min-width: 70px;
            }
        }

        .button {
            border-radius: 35px;
            justify-content: center;
            align-items: center;
            transition: 0.25s;
            height: 35px;
            border: none;

            svg {
                width: 24px;
                path,
                circle {
                    fill: white;
                }
            }

            &.success {
                @include buttonVariant(#59d053);
            }
            &.primary {
                background: rgba(var(--main-color-rgb), 1);
                box-shadow: 0 4px 15px rgba(var(--main-color-rgb), 0.4);
                color: white;

                &:hover {
                    background: rgba(var(--main-color-rgb), 0.9);
                    box-shadow: 0 6px 20px rgba(var(--main-color-rgb), 0.5);
                }
            }
            &.muted {
                @include buttonVariant(#eff2f5, #252525);
            }
        }
    }

    &__referral {
        &-wrapper {
            display: grid;
            grid-template-areas: "status email date code";
            grid-template-columns: 110px 320px 90px auto;
            align-items: center;

            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                    "status date"
                    "email email"
                    "code code";
            }
            @include media-breakpoint-down(sm) {
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                    "status email"
                    "date date"
                    "email email"
                    "code code";
            }
        }

        &-status {
            grid-area: status;
            max-width: 100px;
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(xs) {
            }
        }

        &-email {
            grid-area: email;
            @include flexCenter;
            @extend %icon;
            font-size: 1rem;
            flex: 1 0 auto;
            gap: 5px;
            color: #252525;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
            position: relative;
            padding-left: 1.5rem;

            span[role="img"] {
                position: absolute;
                left: 0;
                top: 5px;
            }

            @include media-breakpoint-down(md) {
                margin: 0.5rem 0;
            }
            @include media-breakpoint-down(sm) {
                margin: 1rem 0;
            }
        }

        &-date {
            grid-area: date;
            white-space: nowrap;
            @include media-breakpoint-down(md) {
                justify-self: flex-end;
            }
        }

        &-info {
        }

        &-actions {
            @include flexCenter;
            grid-area: code;
            justify-self: flex-end;
            gap: 5px;

            @include media-breakpoint-down(md) {
                justify-self: flex-start;
            }

            .discount__generate-box {
                margin: 0;

                @media (max-width: 575.98px) {
                    justify-content: flex-start;
                }

                .code-box__code {
                    margin-left: 0;
                }
            }
        }

        &-amount {
            font-weight: bold;
            color: #9bacb6;
        }
    }

    &__order {
    }

    &__discount {
    }
}
