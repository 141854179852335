@keyframes pulse {
    50% {
        transform: scale(0.9);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.zoomIn {
    animation: zoomIn 0.4s ease-in-out both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 0.4s ease-in-out both;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    animation: fadeInUp 0.4s ease-in-out both;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(100px);
    }
}

.fadeOut {
    animation: fadeOut 0.4s ease-in-out both;
}

@keyframes notifyShow {
    from {
        opacity: 0;
        transform: scale(0.95) translateY(-100px);
    }

    to {
        transform: scale(1);
    }
}

.notifyShow {
    animation: notifyShow 0.4s ease-in-out both;
}

@keyframes notifyHide {
    from {
        opacity: 1;
        transform: scale(1);
    }

    30% {
        opacity: 0.9;
        transform: scale(1.1) translateY(20px);
    }

    to {
        opacity: 0;
        transform: scale(0.9) translateY(-120px);
    }
}

.notifyHide {
    animation: notifyHide 0.6s ease-in-out both;
}

// Spinner
@keyframes atom-spinner-animation-1 {
    100% {
        transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-2 {
    100% {
        transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-3 {
    100% {
        transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    }
}

// Custom popup
@keyframes popUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes ring {
    0% {
        transform: rotate(0deg) translateY(-45%);
    }
    4% {
        transform: rotate(0deg) translateY(-45%);
    }
    5% {
        transform: rotate(-65deg) translateY(-45%);
    }
    6% {
        transform: rotate(0deg) transateY(-45%);
    }
    8% {
        transform: rotate(65deg) translateY(-45%);
    }
    10% {
        transform: rotate(0deg) translateY(-45%);
    }
    13% {
        transform: rotate(-40deg) translateY(-45%);
    }
    15% {
        transform: rotate(0deg) translateY(-45%);
    }
    18% {
        transform: rotate(30deg) translateY(-45%);
    }
    20% {
        transform: rotate(0deg) translateY(-45%);
    }
    24% {
        transform: rotate(-20deg) translateY(-45%);
    }
    25% {
        transform: rotate(0deg) translateY(-45%);
    }
    28% {
        transform: rotate(30deg) translateY(-45%);
    }
    34% {
        transform: rotate(0deg) translateY(-45%);
    }
}

@keyframes badgePop {
    0% {
        transform: scale(0);
    }
    90% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bellShake {
    0% {
        transform: rotate(0deg) translateY(-45%);
    }
    3% {
        transform: rotate(-60deg) translateY(-45%);
    }
    6% {
        transform: rotate(0deg) transateY(-45%);
    }
    10% {
        transform: rotate(60deg) translateY(-45%);
    }
    14% {
        transform: rotate(0deg) translateY(-45%);
    }
    17% {
        transform: rotate(-60deg) translateY(-45%);
    }
    20% {
        transform: rotate(0deg) translateY(-45%);
    }
    24% {
        transform: rotate(60deg) translateY(-45%);
    }
    28% {
        transform: rotate(0deg) translateY(-45%);
    }
}
