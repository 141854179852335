.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    @extend .flex-center;
    padding: 1.2rem 2rem;
    border-radius: 5px;
    min-width: 200px;
    background: white !important; // coz global styles
    box-shadow: 0 4px 14px -2px #1558a809, 0 2px 10px -5px rgba(21, 88, 168, 0.25);
    position: relative;

    :global {
        img {
            height: 65px;
            width: auto;
            max-width: 100%; // coz global styles
            margin: 0 !important; // coz global styles
            object-fit: contain;

            @media (max-width: 660px) {
                height: 55px;
            }
        }
    }
}

.TransferHeader {
    @extend .flex-center;
    font-weight: normal;
    margin-bottom: 1rem;
    text-align: center;

    @media (max-width: 660px) {
        flex-flow: column;
    }

    // Destination
    &__source {
        @extend .card;
        animation-delay: 0.3s;
        border: 1px solid #d2d9f4;
    }

    &__arrow {
        @extend .flex-center;
        border: 1px solid #d2d9f4;
        font-size: 40px;
        width: 55px;
        height: 55px;
        min-width: 55px;
        margin: -0.5rem;
        background: white;
        border-radius: 50%;
        padding: 4px;
        box-sizing: border-box;
        z-index: 10;
        transition: all 0.2s ease;

        @media (max-width: 660px) {
            transform: rotate(90deg);
        }
    }

    // From
    &__target {
        @extend .card;
        animation-delay: 1s;
        border: 2px solid #2d4eed;
        cursor: pointer; // onClick redirect
        transition: all 0.2s ease;

        &:hover {
            box-shadow: 0 4px 18px rgba(#2d4eed, 0.2);
        }

        &:before {
            @extend .flex-center;
            content: "↗";
            font-size: 18px;
            width: 26px;
            height: 26px;
            position: absolute;
            bottom: -0.5rem;
            right: -0.5rem;
            background: #2d4eed;
            color: #fff;
            display: block;
            border-radius: 50%;
        }
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-40px, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}
