.main .login {
    &__wrapper {
        // @extend .overlay-screen;

        // background: radial-gradient(ellipse at center, #fafafa 0, #e4e4e4 100%);
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 7rem 2rem;

        @include media-breakpoint-down(sm) {
            padding: 1rem 0;
        }
    }

    &__input:not(.ant-input) {
        background-color: #fff;
        color: var(--main-color, #fc0);
        display: flex;
        height: 55px;
        border-radius: 12px;
        transition: all 0.25s ease;
        margin-bottom: 0.5rem;
        padding: 0 20px 0 10px;
        width: 270px; // fixed input width (coz: antd)

        &,
        input {
            // also cover case with nested input (coz: antd wrap input with Form.Item)
            margin-left: 0.5rem;
            font-size: 18px;
            font-weight: 500;
            box-shadow: none;
            &::placeholder {
                color: black;
            }
        }

        &,
        &:hover,
        &:focus {
            border: 4px solid $main-color !important;
            align-items: center;
        }

        &:focus {
            border: 4px solid !important;
        }

        &-suffix {
            position: absolute;
            right: 0;
            padding: 1.2rem 0.75rem;
            top: 0;
            bottom: 0;
            font-size: 1.2rem;
        }
    }

    &__logo,
    &__studycrumb {
        opacity: 0;
    }

    &__logo {
        animation: zoomIn 0.4s ease both 0.5s;
        animation-delay: 0.5s;
    }

    &__studycrumb {
        cursor: pointer;
        padding: 0.4rem 0.8rem;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        animation: zoomIn 0.4s ease both 0.8s;
    }

    &__form {
        text-align: center;
        background: #fafafa;
        padding: 2rem;
        border-radius: 15px;
        box-shadow: 0 1px 2px #292f5c12;
        background: #fafafa;

        &-group {
            position: relative;
        }
    }

    &__btn[class] {
        height: unset;
        box-sizing: border-box;
        font-size: 17px;
        border-radius: inherit;
        padding: 0.6rem 2rem;
    }

    &__heading {
        .anticon {
            font-size: 44px;
            // color: var(--main-color, black);
        }
    }

    &__hint {
        text-align: center;
        animation: zoomIn 0.25s ease 0.35s both;
        margin-bottom: 1rem;
    }

    &__link {
        display: block;
        margin-top: 1rem;

        &,
        a {
            font-size: 1rem;
            color: black;
        }
    }

    // Animations
    &__form,
    &__input,
    &__btn,
    &__link {
        opacity: 0;
        animation: fadeInUp 0.25s ease both;
    }
    &__input {
        animation-delay: 0.1s;
    }
    &__btn {
        animation-delay: 0.2s;
    }
    &__link {
        animation-delay: 0.4s;
    }

    // ANTD FORMS
    .ant-form {
        .ant-btn-primary {
            border: none !important;
        }
        .ant-row {
            margin: 0;
        }
        .ant-input {
            border-radius: 0;
            &:focus {
                box-shadow: none !important; // fix
            }
        }
        .ant-form-item-explain {
            opacity: 0.8;
            position: relative; // perfect pixel
            top: -8px; // perfect pixel
            font-weight: 600;
            font-size: 12px;
            color: #ff4d4f;
        }
        .ant-form-item-children-icon {
            top: 12px; // perfect pixel
            bottom: 0;
            height: 80%; // perfect pixel
            display: flex;
            align-items: center;
        }
        .ant-input-prefix:empty {
            display: none; // dump fix
        }

        .link {
            padding: 0.1rem 0.6rem;
            display: inline-flex;
            align-items: center;

            span {
                padding-right: 0.4rem;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
