.SpinFullscreen {
    :global {
        .ant-spin.ant-spin-lg {
            will-change: transform;
            position: fixed;
            top: 25%;
            right: 50%;
            // transform: translate(50%, -50%);
            // width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 1000;

            .ant-spin-dot-spin {
                animation-duration: 0.6s; // spin faster
            }
            .ant-spin-dot-item {
                background: var(--main-color, black);
                width: 8px;
                height: 8px;
            }
        }
    }
}
