@import "variables";
@import "antd";

#page.page-wrapper {
    overflow: visible;
}

.is-messenger-opened {
    @media screen and (max-width: 767px) {
        overflow: hidden;
        position: relative;
    }
}

.section-customer-dashboard {
    // Core
    @import "core";
    @import "mixins";

    @import "global";
    @import "animations";
    @import "intl-tel-input";
    @import "infinite-calendar";

    // Components
    @import "app";
    @import "orderform";
    @import "dashboard";
    @import "login";
    @import "forgot";

    @import "badges";

    padding: 0 0 2rem; // remove useless paddings
}

.section {
    background: transparent !important;
}

.overlay-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@import "overrides"; // import the last!
