// App variables
$success: #78cd62 !default;
$info: #7f93f3 !default;
$warning: #ffb900 !default;
$danger: #cc3032 !default;

$main-color: var(--main-color, #7ac8ed);
$grey-color: var(--grey-color, #f7f9fa);
$error-color: #d76c6d;
$transition: 0.25s;
