.referral {
    &__list {
        display: grid;
        grid-gap: 25px 20px;
        grid-template-columns: repeat(auto-fill, 290px);
        margin: 0;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        li {
            background-color: #f7f9fa;
            box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
            border-radius: 8px;
            cursor: pointer;
            transition: 0.25s;

            &:hover {
                background-color: #f7f9fa;
                box-shadow: 0 15px 35px rgba(19, 22, 23, 0.2);
                transform: translateY(-10px);

                .referral__discount-header {
                    filter: brightness(115%);
                }
            }
        }
    }

    &__discount-card {
        display: flex;
        flex-direction: column;
        min-height: 150px;
        height: 100%;

        &.in-progress {
            .referral__discount-header {
                background-color: #7ac8ed;
            }
        }

        &.ready {
            .referral__discount-header {
                background-color: #78cd62;
            }
        }

        &.used {
            .referral__discount-header {
                background-color: #9bacb6;
            }
        }
    }

    &__discount-header {
        letter-spacing: -0.2px;
        $color: #78cd62;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 8px 8px 0 0;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
        min-height: 43px;
        transition: 0.25s;
    }

    &__discount-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 15px 15px;
    }

    &__generated-wrapper,
    &__used-wrapper {
        display: flex;
        align-items: center;
        margin: 10px 0 0;
    }

    &__generated-wrapper {
        flex-wrap: wrap;
        justify-content: center;

        @include media-breakpoint-down(xs) {
            margin-bottom: -15px;
        }
    }

    &__used-wrapper {
        margin-bottom: 10px;
    }

    &__discount-copy {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;

        &:hover {
            svg {
                fill: #a2b1ff;
            }
        }

        svg {
            width: 18px;
            height: 20px;
            fill: #2d4eed;
            transition: 0.25s;
        }
    }

    &__discount-order {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #4bae4f;
        border-radius: 25px;
        padding: 0.2rem 0.7rem;
        color: #ffffff;
        transition: 0.25s;
        margin: 0 0 15px 15px;

        @include media-breakpoint-only(lg) {
            margin-left: 0;
        }

        &:hover {
            box-shadow: 0 5px 11px 0 rgba(#2d4eed, 0.18), 0 4px 15px 0 rgba(#2d4eed, 0.15);
            color: #fff;
        }
    }

    &__code-box {
        display: inline-flex;
        margin-bottom: 15px;

        @include media-breakpoint-only(lg) {
            justify-content: center;
            width: 100%;
        }
    }

    &__discount-text {
        font-size: 16px;
        transition: 0.25s;
        text-align: center;
        line-height: 22px;

        a {
            color: #2d4eed;

            &:hover {
                color: #a2b1ff;
            }
        }

        &.link {
            font-weight: 600;
            font-size: 18px;
            color: #2d4eed;
            padding: 0 5px;

            &.discount {
                border: 2px dashed rgba(155, 172, 182, 0.6);

                &:hover {
                    color: #a2b1ff;
                }
            }

            &.used {
                border: 2px dashed rgba(155, 172, 182, 0.6);
                text-decoration: line-through;
                color: #9bacb6;
            }
        }

        &.unavailable {
            color: #9bacb6;
        }

        &.code {
            display: flex;
            align-items: center;
            color: #9bacb6;
            margin-right: 5px;
        }
    }

    &__discount-deadline {
        display: inline-block;
        text-align: center;
        color: #9bacb6;
        font-size: 14px;
        margin-top: 10px;
        line-height: 15px;

        &.generated {
            margin-top: 0;

            @include media-breakpoint-down(xs) {
                margin-top: 10px;
            }
        }
    }
}
