$bell-background: white;
$bell-border: red;
$bell-color: black;
.container {
    max-width: 90%;
    margin: auto;
}

.TransferNotice {
    color: black;

    :global {
        .ant-modal-body {
            padding: 3rem 2.5rem;
        }
    }

    &__heading,
    &__description {
        @extend .container;
        margin: 0 auto 1.5rem;
    }

    &__heading {
        font-size: 20px;
        line-height: 1.3;
        display: block;
        font-weight: bold;
    }

    &__description {
        font-size: 15px;
    }

    &__actions {
        @extend .container;
        display: flex;
        :global {
            .ant-space-item,
            .ant-btn {
                width: 100%;
            }
        }
        @media (max-width: 660px) {
            flex-flow: column;
            justify-content: flex-end;
            text-align: center;
        }
    }

    &__hide {
        @extend .container;

        :global {
            span {
                cursor: pointer;
                color: grey;
                border-bottom: 1px dashed silver;
                line-height: 1;
            }
        }
    }

    :global {
        em {
            font-style: normal;
            color: var(--main-color);
        }
    }
}

@keyframes sonar {
    from {
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(2);
    }
}

.Notification {
    margin: 0 auto;
    border: 2px solid rgba(red, 0.6);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: fixed;
    top: 90px;
    right: 30px;
    box-sizing: border-box;
    background: $bell-background;
    z-index: 10000;

    &:after {
        content: "";
        border: 1px solid rgba(red, 0.6);
        // background: red;
        position: absolute;
        left: -2px;
        right: -2px;
        bottom: -2px;
        top: -2px;
        border-radius: 50%;
        z-index: -1;
        animation: sonar 2.5s cubic-bezier(0.46, 0.88, 0.32, 1.275) infinite;
        // animation-iteration-count: 10;
    }

    //Whole ring
    &__ring {
        top: 40%;
        transform: translateY(-45%);
        transform-origin: 50% 0%;
        position: absolute;
        background: $bell-background;
        width: 4px;
        height: 13px;
        margin: 0 auto;
        display: block;
        left: 0;
        right: 0;

        :global {
            animation: 3s 5 ring cubic-bezier(0.46, 0.88, 0.32, 1.275);
            animation-delay: 1.5s;
            animation-fill-mode: both;
        }

        &:before {
            content: "";
            position: absolute;
            background-color: inherit;
        }

        //ring bit
        &:before {
            border-bottom-right-radius: 1100px;
            border-bottom-left-radius: 1100px;
            top: 102%;
            width: 140%;
            height: 3px;
            left: -25%;
            transform-origin: 50% 0%;
            // background: var(--main-color, #2d4eed);
            background: $bell-color;
            margin: 0 auto;
        }
    }

    //Bell body
    &__bell {
        top: 40%;
        transform: translateY(-45%);
        transform-origin: 50% 0%;
        position: absolute;
        // background: var(--main-color, #2d4eed);
        background: $bell-color;
        width: 12px;
        height: 13px;
        border-radius: 28px 28px 0 0;
        margin: 0 auto;
        display: block;
        left: 0;
        right: 0;

        :global {
            animation: 3s 5 bellShake ease-in-out;
            animation-fill-mode: both;
            animation-delay: 1.5s;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            background-color: inherit;
        }

        &:before {
            border-top-left-radius: 1000px;
            border-top-right-radius: 1000px;
            content: "";
            width: 140%;
            height: 30%;
            top: 70%;
            left: -20%;
            background: inherit;
        }

        //invisible bar
        &:after {
            width: 140%;
            height: 2px;
            top: 94%;
            left: -20%;
            background: $bell-background;
        }
    }

    &__badge {
        transform: scale(0);
        display: block;
        width: 19px;
        height: 19px;
        position: absolute;
        top: -7px;
        right: -12px;
        border: 2px solid white;
        border-radius: 50%;
        background: #fc0d5d;
        font-size: 10px;
        line-height: 1;
        font-weight: bold;

        :global {
            animation: 0.2s 1 badgePop cubic-bezier(1, 0.015, 0.295, 1.325);
            animation-fill-mode: both;
            animation-delay: 1.5s;
        }
    }

    &__counter {
        color: #fff;
        text-align: center;
        margin-top: 2px;
    }
}
