.ant-message,
.ant-notification {
    z-index: 10000;
}

.ant-notification {
    .ant-notification-notice {
        border-radius: 8px;
    }
}

.ant-message {
    .ant-message-notice-content {
        border-radius: 15px;
        font-size: 16px;
        max-width: 360px;

        .ant-message-custom-content {
            display: grid;
            grid-template-columns: 35px 1fr;
            align-items: center;
            padding: 5px 0;

            span {
                text-align: left;
                line-height: 1.3;
            }

            .anticon {
                font-size: 20px;
                width: 32px;
                height: 32px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.ant-btn {
    &.ant-btn-primary {
        background: var(--main-color, #7ac8ed);
        box-shadow: 0 3px 8px rgba(var(--main-color-rgb), 0.4);
        border-color: var(--main-color, #7ac8ed) !important;

        &:hover,
        &:focus {
            background: rgba(var(--main-color-rgb), 0.9);
        }
    }
    &.ant-btn-success {
        display: flex;
        align-items: center;
        justify-content: center;

        &,
        &:hover,
        &:focus {
            color: #fff;
            background: $success;
            border-color: $success;
            box-shadow: 0 3px 8px rgba($success, 0.4);
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.ant-badge {
    sup.ant-badge-count {
        display: inline-block;
        min-width: 14px;
        line-height: 1.2;
        font-size: 12px;
        &:empty {
            display: none;
        }
    }
}

.ant-modal {
    border-radius: 15px;

    .ant-modal-header {
        border-radius: 15px 15px 0 0;
    }
}
.ant-modal-root {
    .ant-modal-mask {
        background: rgba(black, 0.3);
        backdrop-filter: blur(2px);
        z-index: 2000;
    }
    .ant-modal-wrap {
        z-index: 2001;
    }
}

.ant-collapse-header {
    display: flex;
    align-items: center;
}

.ant-popover {
    .ant-popover-content {
        border-radius: 12px;
        .ant-popover-message-title {
            font-size: 16px;
        }
        .ant-btn {
            border-radius: 4px;
        }
    }
}
