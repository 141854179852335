#root {
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
}

#modal-root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
}

.main {
    min-height: 420px;
}

.main-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 2rem;
}

section {
    flex-grow: 1;
}

.nav {
    height: 60px;
    display: flex;
    align-items: center;
}

.nav-link {
    display: inline-block;
    padding: 0.2rem 0.9rem;
    border-bottom: 1px solid #ccc;
}

.heading {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #3b454b;

    @include media-breakpoint-down(md) {
        font-size: 48px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 30px;
    }
}

.modal-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(101, 147, 169, 0);
    transition: $transition;

    @include media-breakpoint-down(xs) {
        overflow: auto;
        align-items: start;

        &.centered {
            align-items: center;
        }
    }

    &.visible {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(2px);
    }
}

.modal-inner {
    transform: translateY(-1000px);
    transition: $transition;
    transition-delay: 0.1s;

    &.visible {
        transform: initial;
    }

    .forgot-modal {
        max-width: 680px;
    }
}
